<template>
    <form class="form add-incident__incident-form" @submit.prevent="setStep">
        <span class="add-incident__wizard-step">
            Incident Detail / Step 2 Of 3
        </span>

        <div class="form__inputs-content">
            <div class="form__two-fields-form">
                <div class="input-form">
                    <label class="form__input-label form--required">
                        Date of Incident
                    </label>

                    <input type="date" class="form__input" v-model="incident.date">

                    <div class="form__input-error" v-if="errors.fields.date">
                        {{ errors.fields.date[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Time of Incident
                    </label>

                    <input type="time" class="form__input" v-model="incident.time">

                    <div class="form__input-error" v-if="errors.fields.time">
                        {{ errors.fields.time[0] }}
                    </div>
                </div>
            </div>

            <div class="form__two-fields-form">
                <div class="input-form">
                    <label class="form__input-label form--required">
                        Law Enforcement Names
                    </label>

                    <input type="input" class="form__input" placeholder="Law Enforcement Names"
                        v-model="incident.lwEnforcementNames">

                    <div class="form__input-error" v-if="errors.fields.lwEnforcementNames">
                        {{ errors.fields.lwEnforcementNames[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Medical Assistance Names
                    </label>

                    <input type="input" class="form__input" placeholder="Medical Assistance Names"
                        v-model="incident.mdAsistanceNames">

                    <div class="form__input-error" v-if="errors.fields.mdAsistanceNames">
                        {{ errors.fields.mdAsistanceNames[0] }}
                    </div>
                </div>
            </div>

            <div class="form__two-fields-form">
                <div class="input-form">
                    <label class="form__input-label form--required">
                        Name of Staff Writing Report
                    </label>

                    <input type="input" class="form__input" placeholder="Name of Staff Writing Report"
                        v-model="incident.nmStaff">

                    <div class="form__input-error" v-if="errors.fields.nmStaff">
                        {{ errors.fields.nmStaff[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Position
                    </label>

                    <input type="input" class="form__input" placeholder="Position" v-model="incident.position">

                    <div class="form__input-error" v-if="errors.fields.position">
                        {{ errors.fields.position[0] }}
                    </div>
                </div>
            </div>

            <div class="form__two-fields-form">
                <div class="input-form">
                    <label class="form__input-label form--required">
                        Location of Incident
                    </label>

                    <input type="input" class="form__input" placeholder="Location of Incident"
                        v-model="incident.location">

                    <div class="form__input-error" v-if="errors.fields.location">
                        {{ errors.fields.location[0] }}
                    </div>
                </div>

                <div class="input-form">
                    <label class="form__input-label form--required">
                        Signature of Report Writer
                    </label>

                    <input type="input" class="form__input" placeholder="Signature of Report Writer"
                        v-model="incident.signature">

                    <div class="form__input-error" v-if="errors.fields.signature">
                        {{ errors.fields.signature[0] }}
                    </div>
                </div>
            </div>

            <div class="input-form">
                <label class="form__input-label form--required">
                    Incident Detail
                </label>

                <textarea rows="5" class="form__input" placeholder="Short description of incident"
                    v-model="incident.incident"></textarea>

                <div class="form__input-error" v-if="errors.fields.incident">
                    {{ errors.fields.incident[0] }}
                </div>
            </div>

            <div class="add-incident__evidences">
                <div class="add-incident__evidence-info" v-for="(evidence, index) in incident.evidences" :key="index">
                    {{ evidence.name }}

                    <a href="#" @click.prevent="removeFile(index)">
                        <img src="@/assets/icons/vuesax/linear/close-circle-red.svg" alt="">
                    </a>
                </div>
                
                <label class="add-incident__evidence-add" for="evidences">
                    Add Evidence

                    <img src="@/assets/icons/vuesax/linear/add-circle-green.svg" alt="">

                    <input 
                        type="file" 
                        class="add-incident__evidence-input-file" 
                        accept="image/*, video/*, audio/*" 
                        id="evidences"
                        @change="addEvidence"
                    />
                </label>
            </div>
        </div>

        <div class="form__buttons">
            <button type="button" class="button button--success" @click="previousStep">
                Previous Step
            </button>

            <button type="button" class="button button--success" @click="nextStep">
                Next Step
            </button>
        </div>
    </form>

    <Notifications ref="notification" />
</template>

<script setup>
import { ref, reactive } from 'vue'
import { incidentsServices } from '../Services/IncidentsServices.js'
import Notifications from '@/common/Notifications.vue'

const emits = defineEmits(['currentStep'])
const props = defineProps(['alertId'])
const { validateIncidentFormService, errors } = incidentsServices()
const notification = ref(null)
const incident = reactive({
    date: '',
    time: '',
    lwEnforcementNames: '',
    mdAsistanceNames: '',
    nmStaff: '',
    position: '',
    location: '',
    signature: '',
    incident: '',
    alertId: props.alertId ?? null,
    evidences: []
})

async function validateIncidentForm() {
    const response = await validateIncidentFormService(incident)

    if (response.hasErrors) {
        console.error(`Error validating incident: ${response.message}`)

        // * Show error notification
        return notification.value.sendNotification('error', 'Error', response.message)
    }

    return true
}

// * Add evidence file
function addEvidence(e) {
    const file = e.target.files[0]

    incident.evidences.push(file)
}


// * Remove evidence file
function removeFile(index) {
    incident.evidences.splice(index, 1)
}

// * Previous step
function previousStep(step) {
    const data = { nextStep: 'personsInvolved', data: [] }
    return emits('currentStep', data)
}

// * Next step and emit next step view
async function nextStep() {
    const formValidation = await validateIncidentForm()
    const data = { nextStep: 'witnessesInformation', data: incident }

    if (formValidation) return emits('currentStep', data)
}
</script>