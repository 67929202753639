<template>
    <div class="add-incident modal-form">
        <div class="modal-form__content-modal modal-form--modal-large">
            <router-link class="modal-form__title" to="/">
                <img src="@/assets/icons/vuesax/linear/arrow-left.svg" alt="">
                New Incident
            </router-link>

            <KeepAlive>
                <PersonsInvolvedForm v-if="currentStep === 'personsInvolved'" @current-step="setStep" />
            </KeepAlive>

            <KeepAlive>
                <IncidentDetailForm v-if="currentStep === 'incidentsDetail'" @current-step="setStep" :alert-id="alertId" />
            </KeepAlive>

            <KeepAlive>
                <WitnessesInformationForm v-if="currentStep === 'witnessesInformation'" @current-step="setStep" :incident="incident" />
            </KeepAlive>
        </div>
    </div>
</template>

<script setup>
import PersonsInvolvedForm from '../Components/PersonsInvolvedForm.vue'
import IncidentDetailForm from '../Components/IncidentDetailForm.vue'
import WitnessesInformationForm from '../Components/WitnessesInformationForm.vue'
import { ref, reactive } from 'vue'

const currentStep = ref('personsInvolved')
const props = defineProps(['alertId'])
const incident = reactive({ 
    personsInvolved: '',
    incident: '',
    witnesses: ''
})

function setStep(step) {
    if (step.nextStep === 'incidentsDetail') incident.personsInvolved = step.data
    if (step.nextStep === 'witnessesInformation') incident.incident = step.data

    currentStep.value = step.nextStep
}
</script>