<template>
    <form class="form">
        <span class="add-incident__wizard-step">
            Witnesses Information / Step 3 Of 3

            <a href="#" @click.prevent="addWitness">
                <img src="@/assets/icons/vuesax/linear/add-circle-blue.svg" alt="">
                Add Witness
            </a>
        </span>

        <div class="add-incident__witnesses-scroll">
            <div class="add-incident__snap-scroll" v-for="(witness, index) in witnesses" :key="index">
                <div class="form__section-title-options">
                    <h3 class="form__section-title">Witness #{{ index + 1 }}</h3>

                    <a href="#" class="form__section-title-option" @click.prevent="removeWitness(index)">
                        Remove
                        <img src="@/assets/icons/vuesax/linear/minus.svg" alt="">
                    </a>
                </div>

                <div class="form__inputs-content">
                    <div class="form__two-fields-form">
                        <div class="input-form">
                            <label class="form__input-label form--required">
                                Witness Name
                            </label>

                            <input type="text" class="form__input" placeholder="Witness Name" v-model="witness.name">

                            <div class="form__input-error" v-if="errors.fields[`${index}.name`]">
                                {{ errors.fields[`${index}.name`][0] }}
                            </div>
                        </div>

                        <div class="input-form">
                            <label class="form__input-label form--required">
                                Date of Birth
                            </label>

                            <input type="date" class="form__input" v-model="witness.dob">

                            <div class="form__input-error" v-if="errors.fields[`${index}.dob`]">
                                {{ errors.fields[`${index}.dob`][0] }}
                            </div>
                        </div>
                    </div>

                    <div class="form__two-fields-form form--mt">
                        <div class="input-form">
                            <label class="form__input-label form--required">
                                Phone
                            </label>

                            <input type="text" class="form__input" placeholder="Phone" v-model="witness.phone">

                            <div class="form__input-error" v-if="errors.fields[`${index}.phone`]">
                                {{ errors.fields[`${index}.phone`][0] }}
                            </div>
                        </div>

                        <div class="input-form">
                            <label class="form__input-label form--required">
                                Employee of Hotel
                            </label>

                            <select class="form__input" v-model="witness.empHotel">
                                <option value="">Select Option</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>

                            <div class="form__input-error" v-if="errors.fields[`${index}.empHotel`]">
                                {{ errors.fields[`${index}.empHotel`][0] }}
                            </div>
                        </div>
                    </div>

                    <div class="input-form">
                        <label class="form__input-label form--required">
                            Address
                        </label>

                        <input type="text" class="form__input" placeholder="Address" v-model="witness.address">

                        <div class="form__input-error" v-if="errors.fields[`${index}.address`]">
                            {{ errors.fields[`${index}.address`][0] }}
                        </div>
                    </div>

                    <div class="input-form">
                        <label class="form__input-label form--required">
                            Statement
                        </label>

                        <textarea rows="5" class="form__input" placeholder="Short description of Statement"
                            v-model="witness.statement"></textarea>

                        <div class="form__input-error" v-if="errors.fields[`${index}.statement`]">
                            {{ errors.fields[`${index}.statement`][0] }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form__buttons">
            <button type="button" class="button button--success" @click="previousStep">
                Previous Step
            </button>

            <button type="button" class="button button--success" @click="nextStep" v-if="!buttonLoader">
                Add Incident
            </button>

            <ButtonTextLoader 
                button-class="button button--success" 
                loader-text="Verifying"
                loader-class="button-text-loader__loader-primary" 
                :is-loading="buttonLoader" 
                v-else
            />
        </div>
    </form>

    <Notifications ref="notification" />
</template>

<script setup>
import { ref, reactive, onMounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { incidentsServices } from '../Services/IncidentsServices.js'
import Notifications from '@/common/Notifications.vue'
import { sweetAlerts } from '../../../helpers/SweetAlert.js'
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'
import { usePanicAlertStore } from '@/stores/PanicAlertStore.js'

const { sweetSuccess } = sweetAlerts()
const { getTotalPendingAlerts } = usePanicAlertStore()
const emits = defineEmits(['currentStep'])
const props = defineProps(['incident'])
const router = useRouter()
const { validateWitnessesFormService, addIncidentService, errors, resetErrors } = incidentsServices()
const notification = ref(null)
const buttonLoader = ref(false)
const witnesses = reactive([])
const witness = reactive({
    name: '',
    dob: '',
    phone: '',
    address: '',
    empHotel: '',
    statement: '',
})

// * Push person object on mounted
onMounted(() => { witnesses.push({ ...witness }) })

function addWitness() {
    // * Push person object
    witnesses.push({ ...witness })

    // * Update scroll position
    updateScrollList()
}

async function addIncident() {
    const data = props.incident

    data['witnesses'] = witnesses
    buttonLoader.value = true

    const response = await addIncidentService(data)

    if (response.hasErrors) {
        console.error(`Error adding incident: ${response.exception}`)

        buttonLoader.value = false

        return notification.value.sendNotification('error', 'Error', response.message)
    }

    // * Refresh alerts in peding status
    await getTotalPendingAlerts()

    router.push('/')

    return sweetSuccess(response.data.message)
}

function removeWitness(index) {
    // * Remove person object
    witnesses.splice(index, 1)

    // * Reset errors
    resetErrors()

    // * Add empty person object if the personsInvolved array is empty
    if (!witnesses.length) addWitness()
}

// * Update scroll position
async function updateScrollList() {
    await nextTick()

    // * Set scroll to bottom position
    const scroll = document.querySelector(".add-incident__witnesses-scroll")
    scroll.scroll({ top: scroll.scrollHeight, behavior: "smooth" })
}

async function validateWitnesses() {
    const response = await validateWitnessesFormService(witnesses)

    if (response.hasErrors) {
        // * Set scroll to bottom position
        const elements = document.querySelectorAll('.add-incident__snap-scroll')

        // * Center scroll view in section error
        elements.forEach((el) => {
            if (el.querySelectorAll(".form__input-error").length) {
                return el.scrollIntoView({ block: 'center', behavior: "smooth" })
            }
        })

        console.error(`Error validating persons involved: ${response.message}`)

        // * Show error notification
        return notification.value.sendNotification('error', 'Error', response.message)
    }

    return true
}

// * Previous step
function previousStep() {
    const data = { nextStep: 'incidentsDetail', data: [] }
    return emits('currentStep', data)
}

// * Next step and emit next step view
async function nextStep() {
    const formValidation = await validateWitnesses()

    if (formValidation) addIncident()
}
</script>