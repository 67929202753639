<template>
    <form class="form">
        <span class="add-incident__wizard-step">
            Persons Involved / Step 1 Of 3

            <a href="#" @click.prevent="addPerson">
                <img src="@/assets/icons/vuesax/linear/add-circle-blue.svg" alt="">
                Add Person
            </a>
        </span>

        <div class="add-incident__persons-involved-scroll">
            <div class="add-incident__snap-scroll" v-for="(person, index) in personsInvolved" :key="index">
                <div class="form__section-title-options">
                    <h3 class="form__section-title">Person Involved #{{ index + 1 }}</h3>

                    <a href="#" class="form__section-title-option" @click.prevent="removePerson(index)">
                        Remove
                        <img src="@/assets/icons/vuesax/linear/minus.svg" alt="">
                    </a>
                </div>

                <div class="form__inputs-content">
                    <div class="form__two-fields-form">
                        <div class="input-form">
                            <label class="form__input-label form--required">
                                Full Name
                            </label>

                            <input type="text" class="form__input" placeholder="Full Name" v-model="person.name">

                            <div class="form__input-error" v-if="errors.fields[`${index}.name`]">
                                {{ errors.fields[`${index}.name`][0] }}
                            </div>
                        </div>

                        <div class="input-form">
                            <label class="form__input-label form--required">
                                Street Address
                            </label>

                            <input type="text" class="form__input" placeholder="Street Address"
                                v-model="person.address">

                            <div class="form__input-error" v-if="errors.fields[`${index}.address`]">
                                {{ errors.fields[`${index}.address`][0] }}
                            </div>
                        </div>
                    </div>

                    <div class="form__two-fields-form form--mt">
                        <div class="input-form">
                            <label class="form__input-label form--required">
                                Phone
                            </label>

                            <input type="text" class="form__input" placeholder="Phone" v-model="person.phone">

                            <div class="form__input-error" v-if="errors.fields[`${index}.phone`]">
                                {{ errors.fields[`${index}.phone`][0] }}
                            </div>
                        </div>

                        <div class="input-form">
                            <label class="form__input-label form--required">
                                Date of Birth
                            </label>

                            <input type="date" class="form__input" placeholder="Date of Birth" v-model="person.dob">

                            <div class="form__input-error" v-if="errors.fields[`${index}.dob`]">
                                {{ errors.fields[`${index}.dob`][0] }}
                            </div>
                        </div>
                    </div>

                    <div class="form__two-fields-form form--mt">
                        <div class="input-form">
                            <label class="form__input-label form--required">
                                The Person Involved was
                            </label>

                            <select class="form__input" v-model="person.personType">
                                <option value="">Select Option</option>
                                <option value="Guest">Guest</option>
                                <option value="Employee">Employee</option>
                                <option value="Visitor">Visitor</option>
                                <option value="Vendor">Vendor</option>
                                <option value="Other">Other</option>
                            </select>

                            <div class="form__input-error" v-if="errors.fields[`${index}.personType`]">
                                {{ errors.fields[`${index}.personType`][0] }}
                            </div>
                        </div>

                        <div class="input-form">
                            <label class="form__input-label form--required">
                                Person Making Claim
                            </label>

                            <select class="form__input" v-model="person.personClaim">
                                <option value="">Select Option</option>
                                <option value="Witness">Witness</option>
                                <option value="Complainant">Complainant</option>
                                <option value="Defendant">Defendant</option>
                            </select>

                            <div class="form__input-error" v-if="errors.fields[`${index}.personClaim`]">
                                {{ errors.fields[`${index}.personClaim`][0] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form__buttons">
            <button type="button" class="button button--success" @click="setStep">
                Next Step
            </button>
        </div>
    </form>

    <Notifications ref="notification" />
</template>

<script setup>
import { ref, reactive, onMounted, nextTick } from 'vue'
import { incidentsServices } from '../Services/IncidentsServices.js'
import Notifications from '@/common/Notifications.vue'

const personsInvolved = reactive([])
const { validatePersonsInvolvedFormService, errors, resetErrors } = incidentsServices()
const emits = defineEmits(['currentStep'])
const notification = ref(null)
const person = reactive({
    name: '',
    address: '',
    phone: '',
    dob: '',
    personType: '',
    personClaim: '',
})

// * Push person object on mounted
onMounted(() => { personsInvolved.push({ ...person }) })

function addPerson() {
    // * Push person object
    personsInvolved.push({ ...person })

    // * Update scroll position
    updateScrollList()
}

function removePerson(index) {
    // * Remove person object
    personsInvolved.splice(index, 1)

    // * Reset errors
    resetErrors()

    // * Add empty person object if the personsInvolved array is empty
    if (!personsInvolved.length) addPerson()
}

// * Update scroll position
async function updateScrollList() {
    await nextTick()

    // * Set scroll to bottom position
    const scroll = document.querySelector(".add-incident__persons-involved-scroll")
    scroll.scroll({ top: scroll.scrollHeight, behavior: "smooth" })
}

async function validatePersonsInvolved() {
    const response = await validatePersonsInvolvedFormService(personsInvolved)

    if (response.hasErrors) {
        // * Set scroll to bottom position
        const elements = document.querySelectorAll('.add-incident__snap-scroll')

        // * Center scroll view in section error
        elements.forEach((el) => {
            if (el.querySelectorAll(".form__input-error").length) {
                el.scrollIntoView({ block: 'center', behavior: "smooth" })
            }
        })

        // * Show error notification
        notification.value.sendNotification('error', 'Error', response.message)

        return console.error(`Error validating persons involved: ${response.message}`)
    }

    return true
}

// * Emit next step view
async function setStep(step) {
    const formValidation = await validatePersonsInvolved()
    const data = { nextStep: 'incidentsDetail', data: personsInvolved }

    if (formValidation) return emits('currentStep', data)
}
</script>